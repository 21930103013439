<template>
  <v-row class="ma-3">
    <v-col cols="12">
      <v-toolbar>
        <v-toolbar-title>Cov-Cares Support Network</v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col :cols="12" md="6">
      <v-card>
        <v-card-title>Alert/Note</v-card-title>
        <v-card-text>
          <p>This form is where you can report an alert or a note for a student outside of the Stoplight Survey. This note will be added to CBI by a script, usually within a few hours.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn text to="/cov-cares/alert">Submit Alert/Note</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="stoplightActive" class="mt-6">
        <v-card-title>Tasks</v-card-title>
        <v-card-text>
          <p>This is where a user can review and respond to assigned tasks outside of using CBI.</p>
        </v-card-text>
        <v-card-actions v-if="tasks.length > 0">
          <v-btn to="/cov-cares/tasks">You have {{ tasks.length }} pending task{{ tasks.length === 1 ? '' : 's' }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="stoplightDate" class="mt-6">
        <v-card-title>Stoplight Survey</v-card-title>
        <v-card-text>Stoplight survey will begin {{ stringFormatDate(stoplightDate) }}</v-card-text>
      </v-card>
    </v-col>
    <v-col :cols="12" md="6">
      <v-card v-if="stoplightActive">
        <v-card-title>Stoplight Survey</v-card-title>
        <v-card-text>
          <p>As we wrap up our third full week of classes, please do a quick look at your class rosters and complete a simple Stoplight Survey.  There is no need to even look at grades if you wish - trust your gut as to what you are perceiving about the student's engagement in class.  Simply grade them out as Green, Yellow, Red.</p>
          <ul style="margin-bottom:1em;font-weight:bold;">
            <li>Green: No concerns.</li>
            <li>Yellow: Not yet having significant difficulty, but perhaps showing early signs of struggle and may need intervention later in the semester.</li>
            <li>Red: Already showing signs that he/she is not likely to successfully complete the class without intervention.</li>
          </ul>
          <p>We are chiefly concerned with Red Lights; we would like the opportunity to work with you to intervene.  We will be triangulating your responses with data from the chapel office (excessive early skips), food service (not using meal plan), and observations from residence life, student life, and athletics regarding social engagement.  Those with more than one retention flag will receive more of our attention.</p>
          <p>This faculty survey is a best practice recommended by those who specialize in retention work. <span style="font-weight:bold">Red Lights in their surveys are global retention risks with a 34.2% fall to fall retention rate on average.  Week three is hopefully early enough to have recognized concerns while also early enough to practice some interventions.</span>  Our system of midterm grades is simply too late to have a chance to intervene in a meaningful way with those who have likely made up their minds to leave.  Ideally we would love to have this data by Tuesday.  Thanks for any help you can provide in the midst of your busy schedules. </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" to="/cov-cares/stoplight">Begin Stoplight Survey</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>Tasks</v-card-title>
        <v-card-text>
          <p>Tasks are assigned in CBI by Student Development and the Center for Student Success. You can follow-up on tasks here or in CBI if you have access (there is more detail when following up in CBI).</p>
          <p>You have {{ tasks.length }} pending task{{ tasks.length === 1 ? '' : 's' }}</p>
        </v-card-text>
        <v-card-actions v-if="tasks.length > 0">
          <v-btn to="/cov-cares/tasks">Go to Tasks</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const stoplightActive = ref(false)
    const stoplightDate = ref(null)
    const tasks = ref([])
    onMounted(() => {
      // Find any currently active traditional term
      root.$feathers.service('system/term').find({ query: { type: 'Traditional', start: { $lte: new Date() }, end: { $gte: new Date() } } }).then(({ data }) => {
        if (data.length > 0) {
          if ('stoplight' in data[0]) {
            const { stoplight: { open, close } } = data[0]
            const now = new Date()
            const start = new Date(open)
            const end = new Date(close)
            if (start < now && end > now) {
              stoplightActive.value = true
            } else if (start > now) {
              stoplightDate.value = start
            }
          }
        }
      })
      root.$feathers.service('cov-cares/task').find({ query: { status: { $ne: 'Closed' }, 'assignee.pidm': user.value.pidm } }).then(({ data }) => {
        tasks.value = data
      })
    })

    return {
      stoplightActive,
      stoplightDate,
      tasks,
      stringFormatDate
    }
  }
}
</script>
